import { ReplenishmentPlan } from '@ekkogmbh/apisdk';
import { action, observable } from 'mobx';

export type ReplenishmentPlanState = ReplenishmentPlan & {
  allFilled: boolean;
};

export class ReplenishmentPlanStore {
  @observable
  public editableReplenishmentPlan?: ReplenishmentPlan;

  @observable
  public state: ReplenishmentPlanState = {
    name: '',
    coordinate: '',
    linearStateMachineDefinition: {
      states: [],
      triggers: {},
      onEnterActions: {},
    },
    allFilled: false,
  };

  @action
  public setState = (newState: Partial<ReplenishmentPlanState>, setAllFilled: boolean = true): void => {
    this.state = {
      ...this.state,
      ...newState,
    };

    if (setAllFilled) {
      const allFilled = this.state.name !== '' && this.state.coordinate !== '';
      // add validation
      this.state = {
        ...this.state,
        allFilled,
      };
    }
  };

  @action
  public resetStore = (): void => {
    this.setState(
      {
        name: '',
        coordinate: '',
        linearStateMachineDefinition: {
          states: [],
          triggers: {},
          onEnterActions: {},
        },
        allFilled: false,
      },
      false,
    );

    this.editableReplenishmentPlan = undefined;
  };

  @action
  public setEditableReplenishmentPlan = (replenishmentPlan: ReplenishmentPlan): void => {
    this.editableReplenishmentPlan = replenishmentPlan;

    if (replenishmentPlan) {
      this.setState(
        {
          name: replenishmentPlan.name,
          coordinate: replenishmentPlan.coordinate,
          linearStateMachineDefinition: replenishmentPlan.linearStateMachineDefinition,
          allFilled: true,
        },
        false,
      );
    }
  };
}
