import { EventRule, EventRuleAction, EventTrigger } from '@ekkogmbh/apisdk';
import { Delete, Edit } from '@mui/icons-material';
import { MUIDataTableColumnDef } from 'mui-datatables';
import React from 'react';
import { DatatableColumnDefinitionFn } from 'src/Common/Components/DataTable';
import { ActionButton, DataTableActionsComponent } from 'src/Common/Components/DataTable/DataTableActions';
import {
  EventRuleManagementContentActionHandlers,
  EventRuleManagementContentPropsWithStores,
  EventRuleManagementContentState,
} from './EventRuleManagementContent';
import { Typography } from '@mui/material';

class EventRuleDataTableActions extends DataTableActionsComponent<EventRule> {}

const Actions = (
  _: EventRuleManagementContentState,
  __: EventRuleManagementContentPropsWithStores,
  actions: EventRuleManagementContentActionHandlers,
): MUIDataTableColumnDef => ({
  label: ' ',
  name: 'entry',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (EventRule: EventRule): React.ReactNode => {
      const actionButtons: ActionButton<EventRule>[] = [
        {
          title: 'Edit',
          onClick: actions.edit,
          icon: Edit,
        },
        {
          title: 'Delete',
          onClick: actions.delete,
          icon: Delete,
        },
      ];

      return <EventRuleDataTableActions dataset={EventRule} isProcessing={false} actionButtons={actionButtons} />;
    },
  },
});

const Name = (): MUIDataTableColumnDef => ({
  label: 'Name',
  name: 'entry.name',
  options: {
    sort: true,
    filter: false,
    customBodyRender: (name: EventRule['name']): React.ReactNode => <div style={{ fontWeight: 700 }}>{name}</div>,
  },
});

const Coordinate = (): MUIDataTableColumnDef => ({
  label: 'Coordinate',
  name: 'entry.coordinate',
  options: {
    sort: true,
    filter: false,
    customBodyRender: (coordinate: EventRule['coordinate']): React.ReactNode => (
      <div style={{ fontWeight: 700 }}>{coordinate}</div>
    ),
  },
});

const Action = (): MUIDataTableColumnDef => ({
  label: 'Action',
  name: 'entry.action',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (action: EventRuleAction) => <Typography>{action.type}</Typography>,
  },
});

const Trigger = (): MUIDataTableColumnDef => ({
  label: 'Trigger',
  name: 'entry.trigger',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (trigger: EventTrigger) => <Typography>{trigger.type}</Typography>,
  },
});

export const materialDatatableColumnDefinitions: Array<DatatableColumnDefinitionFn<
  EventRuleManagementContentPropsWithStores,
  EventRuleManagementContentState,
  EventRuleManagementContentActionHandlers
>> = [Name, Coordinate, Trigger, Action, Actions];
