import { Node, UserPermission } from '@ekkogmbh/apisdk';

export const NodeSeparator = '/';

/** @deprecated is unreliable since the scope of permissions may vary. should use coordinates and check permissions with backend instead. */
export const getOverrideRootNodesFromUserPermissions = (userPermissions: UserPermission[]): Node[] => {
  const mapper = (userPermission: UserPermission): Node => {
    const definitionValues = userPermission.nodeDefinition.nodeValuesDefinition.values;
    const definitionTypes = userPermission.nodeDefinition.nodeTypesDefinition.types;
    const value = definitionValues[definitionValues.length - 1];
    const type = definitionTypes[definitionTypes.length - 1];
    const id = userPermission.nodeId;
    const path = definitionValues.join(NodeSeparator);

    return {
      id,
      value,
      type,
      path,
      coordinate: '', // cant recreate here realibly
      hasChildren: true, // doesnt matter
      parentId: null, // must be null even though its no a usecase
      annotations: {}, // doesnt matter
    };
  };

  const isNodeAncestorOfNode = (leftNodeValues: string[], rightNodeValues: string[]): boolean => {
    let index = 0;

    while (leftNodeValues[index] !== undefined && rightNodeValues[index] !== undefined) {
      if (leftNodeValues[index] !== rightNodeValues[index]) {
        return false;
      }

      index++;
    }

    return leftNodeValues[index] === undefined && rightNodeValues[index] !== undefined;
  };

  const filter = (filterNode: Node) => (currNode: Node): boolean =>
    isNodeAncestorOfNode(currNode.path.split(NodeSeparator), filterNode.path.split(NodeSeparator));

  const reducer = (accNodes: Node[], currNode: Node, _: number, nodes: Node[]): Node[] => {
    const filtered = nodes.filter(filter(currNode));

    if (filtered.length === 0) {
      accNodes.push(currNode);
    }

    return accNodes;
  };

  const allPermissionNodes = userPermissions.map(mapper);

  return allPermissionNodes.reduce(reducer, []);
};
