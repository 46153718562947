import { Provider } from 'mobx-react';
import React from 'react';
import { Page } from '../Common/Components/Page';
import { ReplenishmentPlanManagementContent } from './Components/ReplenishmentPlanManagementContent';
import { ReplenishmentPlanStore } from './Stores/ReplenishmentPlanStore';
import { EventTriggerStore } from 'src/EventRuleManagement/Stores/EventTriggerStore';

const replenishmentPlanStore = new ReplenishmentPlanStore();
const eventTriggerStore = new EventTriggerStore();

const stores = { replenishmentPlanStore, eventTriggerStore };

export const ReplenishmentPlanManagement = (): React.JSX.Element => (
  <Provider {...stores}>
    <Page title="Replenishment Plans" isSearchable={true}>
      <ReplenishmentPlanManagementContent />
    </Page>
  </Provider>
);
