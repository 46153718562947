import { Node } from '@ekkogmbh/apisdk';
import { Fade, Grid, SelectChangeEvent } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import TextField from '@mui/material/TextField/TextField';
import { enqueueSnackbar } from 'notistack';
import React, { ChangeEvent, Component } from 'react';
import { LoadingMask } from 'src/Common/Components/LoadingMask';
import { StyledSelectField } from '../../Common/Components/Forms/StyledSelectField';
import { GenericDialog } from '../../Common/Components/GenericDialog';
import { FormPanelButtons, PanelAction } from '../../Common/Components/FormPanelButtons';
import { FormStyles } from '../../Common/Styles/FormStyles';

const styles = FormStyles;
const fadeTimeout = 2000;

interface AreaPanelState {
  action: PanelAction;
  value: string;
  changed: boolean;
  loading: boolean;
  deleteDialogOpen: boolean;
  nodeId: number;
}

interface AreaPanelProps extends WithStyles<typeof styles> {
  closeHandler: () => void;
  saveHandler: (value: string, parentNode: Node) => Promise<Node>;
  deleteHandler: (node: Node) => Promise<void>;
  userRootNodes: Node[];
  childnodes: Node[];
  node?: Node;
}

class AreaPanelComponent extends Component<AreaPanelProps> {
  public state: AreaPanelState;

  private tabIndex: number = 1;

  constructor(props: AreaPanelProps) {
    super(props);

    const { node, userRootNodes } = props;

    let nodeId = userRootNodes[0].id;

    if (node) {
      nodeId = node.id;
    }

    this.state = {
      action: PanelAction.CREATE,
      value: '',
      changed: false,
      loading: false,
      deleteDialogOpen: false,
      nodeId,
    };
  }

  // @TODO
  // eslint-disable-next-line react/no-deprecated
  public componentWillReceiveProps(nextProps: Readonly<AreaPanelProps>): void {
    const { node, userRootNodes } = nextProps;

    let nodeId = userRootNodes[0].id;

    if (node) {
      nodeId = node.id;
    }

    this.setState({ nodeId });
  }

  public resetState = () => {
    this.setState({
      value: '',
      changed: false,
    });
  };

  public onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    this.setState({
      value,
      changed: true,
    });
  };

  public onChangeRootNode = (event: SelectChangeEvent<unknown>) => {
    const { value } = event.target;

    this.setState({ nodeId: parseInt(value as string, 10) });
  };

  public onSave = async () => {
    const { closeHandler, saveHandler, node, userRootNodes, childnodes } = this.props;

    const { value, nodeId } = this.state;

    this.setState({ loading: true });

    let parentNode = userRootNodes[0];

    if (node && nodeId === node.id) {
      parentNode = node;
    } else {
      const allNodes = userRootNodes.concat(childnodes.filter((childNode: Node) => !childNode.hasChildren));
      const filtered = allNodes.filter((curr: Node) => nodeId === curr.id);
      parentNode = filtered[0];
    }

    try {
      await saveHandler(value, parentNode);

      this.setState({ loading: false });
      closeHandler();
    } catch (e) {
      enqueueSnackbar((e as Error).message, { variant: 'error' });
      this.setState({ loading: false });
    }
  };

  public onCancel = () => {
    const { closeHandler } = this.props;
    this.resetState();
    closeHandler();
  };

  public onDelete = () => {
    this.setState({ deleteDialogOpen: true });
  };

  public onDeleteDismiss = () => {
    this.setState({ deleteDialogOpen: false });
  };

  public onDeleteOk = async (): Promise<void> => {
    const { node, closeHandler, deleteHandler } = this.props;

    this.setState({
      loading: true,
      deleteDialogOpen: false,
    });

    await deleteHandler(node!);

    this.setState({ loading: false });
    closeHandler();
  };

  public getTabIndex = (): number => this.tabIndex++;

  public render() {
    const { node, classes, userRootNodes, childnodes } = this.props;
    const { action, value, changed, loading, nodeId, deleteDialogOpen } = this.state;

    const deleteDialogText = node ? `Delete Area: ${node}?` : '';

    const mapFn = (curr: Node) => (
      <option value={curr.id} key={'nodeoption-' + curr.id}>
        {curr.path}
      </option>
    );

    let pathOptions: React.JSX.Element[] = [];
    const childnodesWithoutChildren = childnodes.filter((childNode: Node) => !childNode.hasChildren);

    if (node) {
      childnodesWithoutChildren.unshift(node);
      pathOptions = childnodesWithoutChildren.map(mapFn);
    } else {
      const pathNodes = userRootNodes.concat(childnodesWithoutChildren);
      pathOptions = pathNodes.map(mapFn);
    }

    return (
      <Grid container spacing={2} alignItems={'stretch'}>
        {action === PanelAction.EDIT && (
          <GenericDialog
            type="confirmation"
            open={deleteDialogOpen}
            title={'Delete Link'}
            text={deleteDialogText}
            onClose={this.onDeleteDismiss}
            onConfirm={this.onDeleteOk}
          />
        )}
        {loading && <LoadingMask />}
        <Grid item xs={12}>
          <Fade in={true} timeout={fadeTimeout}>
            <Grid container spacing={2} alignItems={'stretch'}>
              <Grid item lg={4} xs={12} style={{ minHeight: 148 }}>
                {node && childnodesWithoutChildren.length === 0 ? (
                  <TextField
                    className={classes.margin}
                    InputLabelProps={{
                      classes: {
                        root: classes.label,
                        focused: classes.focused,
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.outlinedInput,
                        focused: classes.focused,
                        notchedOutline: classes.notchedOutline,
                        disabled: classes.disabled,
                      },
                    }}
                    label="Path"
                    value={node.path}
                    variant="outlined"
                    id="custom-css-outlined-input"
                    disabled={true}
                  />
                ) : (
                  <StyledSelectField native value={nodeId} onChange={this.onChangeRootNode} label="Path">
                    {pathOptions}
                  </StyledSelectField>
                )}
              </Grid>
              <Grid item lg={6} xs={12}>
                <TextField
                  className={classes.margin}
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                      focused: classes.focused,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.outlinedInput,
                      focused: classes.focused,
                      notchedOutline: classes.notchedOutline,
                      disabled: classes.disabled,
                    },
                  }}
                  onChange={this.onChangeValue}
                  label="Name"
                  value={value}
                  variant="outlined"
                  id="custom-css-outlined-input"
                />
              </Grid>
            </Grid>
          </Fade>
        </Grid>

        <FormPanelButtons
          cancelHandler={this.onCancel}
          resetHandler={this.resetState}
          saveHandler={this.onSave}
          deleteHandler={this.onDelete}
          isResetDisabled={!changed}
          isSaveDisabled={!changed || !value}
          isDeleteDisabled={action !== PanelAction.EDIT}
          isDeleteHidden={true}
        />
      </Grid>
    );
  }
}

const StyleWrapped = withStyles(styles)(AreaPanelComponent);

export const AreaPanel = StyleWrapped;
