import { PanelConfiguration, PanelConfigurationDisplayMode, UiConfiguration } from '@ekkogmbh/apisdk';
import { Box, Checkbox, Fade, FormControlLabel, Grid, ListItemText, MenuItem, Stack, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { StyledSelectField } from 'src/Common/Components/Forms/StyledSelectField';
import { ApiStore } from 'src/Common/Stores/ApiStore';
import { FormStyles } from 'src/Common/Styles/FormStyles';
import { StyledTextField } from '../../Common/Components/Forms/StyledTextField';
import { PickerProfileStore } from '../Stores/PickerProfileStore';

const styles = FormStyles;

interface PickerProfileUserInterfaceFormState {}

interface PickerProfileUserInterfaceFormProps extends WithStyles<typeof styles> {
  errorCallback: () => void;
}

interface PickerProfileUserInterfaceFormStores {
  api: ApiStore;
  pickerProfileStore: PickerProfileStore;
}

type PickerProfileUserInterfaceFormPropsWithStores = PickerProfileUserInterfaceFormProps &
  PickerProfileUserInterfaceFormStores;

@inject('api', 'pickerProfileStore')
@observer
class PickerProfileUserInterfaceFormComponent extends Component<
  PickerProfileUserInterfaceFormProps,
  PickerProfileUserInterfaceFormState
> {
  public state: PickerProfileUserInterfaceFormState = {};

  get stores(): PickerProfileUserInterfaceFormPropsWithStores {
    return this.props as PickerProfileUserInterfaceFormPropsWithStores;
  }

  public async componentDidMount(): Promise<void> {
    const { sourcePanel, destinationPanel } = this.stores.pickerProfileStore.state;

    // initialize state for pre–selected SelectFields
    if (sourcePanel === undefined) {
      this.stores.pickerProfileStore.setState({
        sourcePanel: {
          displayMode: PanelConfigurationDisplayMode.DEFAULT,
          fieldConfig: { primary: '', secondary: '' },
          infos: {
            hidePrimaryField: false,
            hideSecondaryField: false,
            hideCoordinate: false,
            hideValue: false,
          },
        },
      });
    }
    if (destinationPanel === undefined) {
      this.stores.pickerProfileStore.setState({
        destinationPanel: {
          displayMode: PanelConfigurationDisplayMode.DEFAULT,
          fieldConfig: { primary: '', secondary: '' },
          infos: {
            hidePrimaryField: false,
            hideSecondaryField: false,
            hideCoordinate: false,
            hideValue: false,
          },
        },
      });
    }
  }

  private setPanel(key: keyof UiConfiguration, panel: PanelConfiguration): void {
    const { sourcePanel, destinationPanel } = this.stores.pickerProfileStore.state;

    const uiConfiguration = {
      ...{ sourcePanel, destinationPanel },
      [key]: panel,
    };

    this.stores.pickerProfileStore.setState({ ...uiConfiguration });
  }

  public render(): React.JSX.Element {
    const { sourcePanel, destinationPanel } = this.stores.pickerProfileStore.state;

    return (
      <Fade in={true} timeout={1000}>
        <Grid container alignItems="stretch">
          <Grid container item xs={12} spacing={4} alignItems="stretch" alignContent="stretch">
            <Grid item xs={6}>
              <Typography
                variant="overline"
                color="primary"
                style={{ display: 'inline-block', fontWeight: 700, textAlign: 'center', width: '100%' }}
              >
                Source Panel
              </Typography>
              <StyledSelectField
                label="Display Mode"
                value={sourcePanel?.displayMode ?? ''}
                onChange={(e) =>
                  this.setPanel('sourcePanel', {
                    displayMode: e.target.value as PanelConfiguration['displayMode'],
                    fieldConfig: sourcePanel?.fieldConfig ?? { primary: '', secondary: '' },
                  })
                }
              >
                {Object.values(PanelConfigurationDisplayMode).map(
                  (mode: PanelConfigurationDisplayMode, index: number) => (
                    <MenuItem key={index} value={mode}>
                      <ListItemText primary={mode} />
                    </MenuItem>
                  ),
                )}
              </StyledSelectField>
              {!!sourcePanel?.displayMode && (
                <>
                  <StyledTextField
                    type="text"
                    label="Primary Field"
                    value={sourcePanel.fieldConfig?.primary ?? ''}
                    onChange={(e) =>
                      this.setPanel('sourcePanel', {
                        displayMode: (sourcePanel as PanelConfiguration).displayMode,
                        fieldConfig: {
                          primary: e.target.value,
                          secondary: sourcePanel.fieldConfig?.secondary ?? '',
                        },
                      })
                    }
                  />
                  <StyledTextField
                    type="text"
                    label="Secondary Field"
                    value={sourcePanel.fieldConfig?.secondary ?? ''}
                    onChange={(e) =>
                      this.setPanel('sourcePanel', {
                        displayMode: (sourcePanel as PanelConfiguration).displayMode,
                        fieldConfig: {
                          primary: sourcePanel.fieldConfig?.primary ?? '',
                          secondary: e.target.value,
                        },
                      })
                    }
                  />
                  <Typography
                    variant="overline"
                    color="secondary"
                    style={{ display: 'inline-block', fontWeight: 700, paddingTop: 8, paddingLeft: 8 }}
                  >
                    Hide Information
                  </Typography>
                  <Stack direction={'row'}>
                    <Box>
                      <FormControlLabel
                        style={{ margin: 12 }}
                        label="Coordinate"
                        control={
                          <Checkbox
                            checked={sourcePanel.infos?.hideCoordinate ?? false}
                            onChange={(e) => {
                              this.setPanel('sourcePanel', {
                                displayMode: (sourcePanel as PanelConfiguration).displayMode,
                                fieldConfig: {
                                  primary: sourcePanel.fieldConfig?.primary ?? '',
                                  secondary: sourcePanel.fieldConfig?.secondary ?? '',
                                },
                                infos: {
                                  hidePrimaryField: sourcePanel.infos?.hidePrimaryField ?? false,
                                  hideSecondaryField: sourcePanel.infos?.hideSecondaryField ?? false,
                                  hideCoordinate: e.target.checked,
                                  hideValue: sourcePanel.infos?.hideValue ?? false,
                                },
                              });
                            }}
                            color="secondary"
                          />
                        }
                      />
                    </Box>
                    <Box>
                      <FormControlLabel
                        style={{ margin: 12 }}
                        label="Value"
                        control={
                          <Checkbox
                            checked={sourcePanel.infos?.hideValue ?? false}
                            onChange={(e) => {
                              this.setPanel('sourcePanel', {
                                displayMode: (sourcePanel as PanelConfiguration).displayMode,
                                fieldConfig: {
                                  primary: sourcePanel.fieldConfig?.primary ?? '',
                                  secondary: sourcePanel.fieldConfig?.secondary ?? '',
                                },
                                infos: {
                                  hidePrimaryField: sourcePanel.infos?.hidePrimaryField ?? false,
                                  hideSecondaryField: sourcePanel.infos?.hideSecondaryField ?? false,
                                  hideCoordinate: sourcePanel.infos?.hideCoordinate ?? false,
                                  hideValue: e.target.checked,
                                },
                              });
                            }}
                            color="secondary"
                          />
                        }
                      />
                    </Box>
                    <Box>
                      <FormControlLabel
                        style={{ margin: 12 }}
                        label="Primary Field"
                        control={
                          <Checkbox
                            checked={sourcePanel.infos?.hidePrimaryField ?? false}
                            onChange={(e) => {
                              this.setPanel('sourcePanel', {
                                displayMode: (sourcePanel as PanelConfiguration).displayMode,
                                fieldConfig: {
                                  primary: sourcePanel.fieldConfig?.primary ?? '',
                                  secondary: sourcePanel.fieldConfig?.secondary ?? '',
                                },
                                infos: {
                                  hidePrimaryField: e.target.checked,
                                  hideSecondaryField: sourcePanel.infos?.hideSecondaryField ?? false,
                                  hideCoordinate: sourcePanel.infos?.hideCoordinate ?? false,
                                  hideValue: sourcePanel.infos?.hideValue ?? false,
                                },
                              });
                            }}
                            color="secondary"
                          />
                        }
                      />
                    </Box>
                    <Box>
                      <FormControlLabel
                        style={{ margin: 12 }}
                        label="Secondary Field"
                        control={
                          <Checkbox
                            checked={sourcePanel.infos?.hideSecondaryField ?? false}
                            onChange={(e) => {
                              this.setPanel('sourcePanel', {
                                displayMode: (sourcePanel as PanelConfiguration).displayMode,
                                fieldConfig: {
                                  primary: sourcePanel.fieldConfig?.primary ?? '',
                                  secondary: sourcePanel.fieldConfig?.secondary ?? '',
                                },
                                infos: {
                                  hidePrimaryField: sourcePanel.infos?.hidePrimaryField ?? false,
                                  hideSecondaryField: e.target.checked,
                                  hideCoordinate: sourcePanel.infos?.hideCoordinate ?? false,
                                  hideValue: sourcePanel.infos?.hideValue ?? false,
                                },
                              });
                            }}
                            color="secondary"
                          />
                        }
                      />
                    </Box>
                  </Stack>
                </>
              )}
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="overline"
                color="primary"
                style={{ display: 'inline-block', fontWeight: 700, textAlign: 'center', width: '100%' }}
              >
                Destination Panel
              </Typography>
              <StyledSelectField
                label="Display Mode"
                value={destinationPanel?.displayMode ?? ''}
                onChange={(e) =>
                  this.setPanel('destinationPanel', {
                    displayMode: e.target.value as PanelConfiguration['displayMode'],
                    fieldConfig: destinationPanel?.fieldConfig ?? { primary: '', secondary: '' },
                  })
                }
              >
                {Object.values(PanelConfigurationDisplayMode).map(
                  (mode: PanelConfigurationDisplayMode, index: number) => (
                    <MenuItem key={index} value={mode}>
                      <ListItemText primary={mode} />
                    </MenuItem>
                  ),
                )}
              </StyledSelectField>
              {!!destinationPanel?.displayMode && (
                <>
                  <StyledTextField
                    type="text"
                    label="Primary Field"
                    value={destinationPanel.fieldConfig?.primary ?? ''}
                    onChange={(e) =>
                      this.setPanel('destinationPanel', {
                        displayMode: (destinationPanel as PanelConfiguration).displayMode,
                        fieldConfig: {
                          primary: e.target.value,
                          secondary: destinationPanel.fieldConfig?.secondary ?? '',
                        },
                      })
                    }
                  />
                  <StyledTextField
                    type="text"
                    label="Secondary Field"
                    value={destinationPanel.fieldConfig?.secondary ?? ''}
                    onChange={(e) =>
                      this.setPanel('destinationPanel', {
                        displayMode: (destinationPanel as PanelConfiguration).displayMode,
                        fieldConfig: {
                          primary: destinationPanel.fieldConfig?.primary ?? '',
                          secondary: e.target.value,
                        },
                      })
                    }
                  />
                  <Typography
                    variant="overline"
                    color="secondary"
                    style={{ display: 'inline-block', fontWeight: 700, paddingTop: 8, paddingLeft: 8 }}
                  >
                    Hide Information
                  </Typography>
                  <Stack direction={'row'}>
                    <Box>
                      <FormControlLabel
                        style={{ margin: 12 }}
                        label="Coordinate"
                        control={
                          <Checkbox
                            checked={destinationPanel.infos?.hideCoordinate ?? false}
                            onChange={(e) => {
                              this.setPanel('destinationPanel', {
                                displayMode: (destinationPanel as PanelConfiguration).displayMode,
                                fieldConfig: {
                                  primary: destinationPanel.fieldConfig?.primary ?? '',
                                  secondary: destinationPanel.fieldConfig?.secondary ?? '',
                                },
                                infos: {
                                  hidePrimaryField: destinationPanel.infos?.hidePrimaryField ?? false,
                                  hideSecondaryField: destinationPanel.infos?.hideSecondaryField ?? false,
                                  hideCoordinate: e.target.checked,
                                  hideValue: destinationPanel.infos?.hideValue ?? false,
                                },
                              });
                            }}
                            color="secondary"
                          />
                        }
                      />
                    </Box>
                    <Box>
                      <FormControlLabel
                        style={{ margin: 12 }}
                        label="Value"
                        control={
                          <Checkbox
                            checked={destinationPanel.infos?.hideValue ?? false}
                            onChange={(e) => {
                              this.setPanel('destinationPanel', {
                                displayMode: (destinationPanel as PanelConfiguration).displayMode,
                                fieldConfig: {
                                  primary: destinationPanel.fieldConfig?.primary ?? '',
                                  secondary: destinationPanel.fieldConfig?.secondary ?? '',
                                },
                                infos: {
                                  hidePrimaryField: destinationPanel.infos?.hidePrimaryField ?? false,
                                  hideSecondaryField: destinationPanel.infos?.hideSecondaryField ?? false,
                                  hideCoordinate: destinationPanel.infos?.hideCoordinate ?? false,
                                  hideValue: e.target.checked,
                                },
                              });
                            }}
                            color="secondary"
                          />
                        }
                      />
                    </Box>
                    <Box>
                      <FormControlLabel
                        style={{ margin: 12 }}
                        label="Primary Field"
                        control={
                          <Checkbox
                            checked={destinationPanel.infos?.hidePrimaryField ?? false}
                            onChange={(e) => {
                              this.setPanel('destinationPanel', {
                                displayMode: (destinationPanel as PanelConfiguration).displayMode,
                                fieldConfig: {
                                  primary: destinationPanel.fieldConfig?.primary ?? '',
                                  secondary: destinationPanel.fieldConfig?.secondary ?? '',
                                },
                                infos: {
                                  hidePrimaryField: e.target.checked,
                                  hideSecondaryField: destinationPanel.infos?.hideSecondaryField ?? false,
                                  hideCoordinate: destinationPanel.infos?.hideCoordinate ?? false,
                                  hideValue: destinationPanel.infos?.hideValue ?? false,
                                },
                              });
                            }}
                            color="secondary"
                          />
                        }
                      />
                    </Box>
                    <Box>
                      <FormControlLabel
                        style={{ margin: 12 }}
                        label="Secondary Field"
                        control={
                          <Checkbox
                            checked={destinationPanel.infos?.hideSecondaryField ?? false}
                            onChange={(e) => {
                              this.setPanel('destinationPanel', {
                                displayMode: (destinationPanel as PanelConfiguration).displayMode,
                                fieldConfig: {
                                  primary: destinationPanel.fieldConfig?.primary ?? '',
                                  secondary: destinationPanel.fieldConfig?.secondary ?? '',
                                },
                                infos: {
                                  hidePrimaryField: destinationPanel.infos?.hidePrimaryField ?? false,
                                  hideSecondaryField: e.target.checked,
                                  hideCoordinate: destinationPanel.infos?.hideCoordinate ?? false,
                                  hideValue: destinationPanel.infos?.hideValue ?? false,
                                },
                              });
                            }}
                            color="secondary"
                          />
                        }
                      />
                    </Box>
                  </Stack>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Fade>
    );
  }
}

const StyleWrapped = withStyles(styles)(PickerProfileUserInterfaceFormComponent);

export const PickerProfileUserInterfaceForm = StyleWrapped;
