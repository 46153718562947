import { User, UserCreatePayload, UserUpdatePayload } from '@ekkogmbh/apisdk';
import { Fade, Grid, Stack } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { DatePicker } from '@mui/x-date-pickers';
import moment, { Moment } from 'moment';
import { enqueueSnackbar } from 'notistack';
import React, { ChangeEvent, ClipboardEvent } from 'react';
import { GenericDialog } from '../../Common/Components/GenericDialog';
import { FormPanelButtons, PanelAction } from '../../Common/Components/FormPanelButtons';
import { LoadingMask } from '../../Common/Components/LoadingMask';
import { FormStyles } from '../../Common/Styles/FormStyles';
import { StyledTextField } from 'src/Common/Components/Forms/StyledTextField';
import { CoordinateInput } from 'src/Common/Components/CoordinateInput';
import { StyledCheckbox } from 'src/Common/Components/Forms/StyledCheckbox';

const styles = FormStyles;
const fadeTimeout = 2000;

interface UserPanelState {
  action: PanelAction;
  username: string;
  coordinate: string;
  email: string;
  validUntil: string;
  automation: boolean;
  password: string;
  passwordConfirm: string;
  emailError: boolean;
  changed: boolean;
  loading: boolean;
  deleteDialogOpen: boolean;
  allFilled: boolean;
}

interface UserPanelProps extends WithStyles<typeof styles> {
  user?: User;
  closeHandler: () => void;
  saveHandler: (user: UserUpdatePayload | UserCreatePayload, password?: string) => Promise<void>;
  deleteHandler: (user: User) => Promise<void>;
  passwordEditable?: boolean;
}

class UserPanelComponent extends React.PureComponent<UserPanelProps, UserPanelState> {
  public state: UserPanelState = {
    action: PanelAction.CREATE,
    username: '',
    coordinate: '',
    email: '',
    automation: false,
    validUntil: '',
    password: '',
    passwordConfirm: '',
    emailError: false,
    changed: false,
    loading: false,
    deleteDialogOpen: false,
    allFilled: false,
  };

  constructor(props: UserPanelProps) {
    super(props);

    const { user } = this.props;

    if (user !== undefined) {
      const action = PanelAction.EDIT;
      const { username, email, coordinate } = user;

      const validUntil = moment(user.validUntil).format('YYYY-MM-DD');

      this.state = {
        ...this.state,
        action,
        username,
        email,
        coordinate,
        validUntil,
      };
    }
  }

  // @TODO deprecated, maybe refactor to getDerivedStateFromProps
  // eslint-disable-next-line react/no-deprecated
  public componentWillReceiveProps(nextProps: Readonly<UserPanelProps>): void {
    const { user } = nextProps;
    const { user: currentUser } = this.props;

    const nextUser = JSON.stringify(user);
    const currUser = JSON.stringify(currentUser);

    if (nextUser !== currUser) {
      this.setState({
        username: '',
        email: '',
        coordinate: '',
        validUntil: '',
        automation: false,
        password: '',
        passwordConfirm: '',
      });

      if (user) {
        const { username, email, coordinate, automation } = user;

        const validUntil = moment(user.validUntil).format('YYYY-MM-DD');

        this.setState({
          action: PanelAction.EDIT,
          username,
          email,
          coordinate,
          validUntil,
          automation: !!automation,
          password: '',
          passwordConfirm: '',
        });
      }
    }
  }

  public resetState = () => {
    const { user } = this.props;

    if (user) {
      const { username, email, coordinate, automation } = user;

      const validUntil = moment(user.validUntil).format('YYYY-MM-DD');

      this.setState({
        username,
        email,
        coordinate,
        validUntil,
        password: '',
        passwordConfirm: '',
        changed: false,
        automation: !!automation,
      });
    } else {
      this.setState({
        username: '',
        email: '',
        coordinate: '',
        validUntil: '',
        password: '',
        passwordConfirm: '',
        changed: false,
        automation: false,
      });
    }
  };

  public onPaste = (event: ClipboardEvent<HTMLInputElement>) => {
    event.persist();
    // @TODO ¯\_(ツ)_/¯
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setTimeout(() => this.onChange(event as any), 500);
  };

  public onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const state = { changed: true };

    state[name] = value;

    let emailError = false;

    if (name === 'email') {
      emailError = !this.validateEmail(value);
    }

    this.setState(
      {
        ...state,
        emailError,
      },
      this.setAllFilled,
    );
  };

  public handleChangeValidUntil = (date: Moment | null) => {
    if (date == null) {
      this.setState({ changed: true, allFilled: false });

      return;
    }

    const changed = true;
    const validUntil = date.format('YYYY-MM-DD');

    this.setState(
      {
        validUntil,
        changed,
      },
      this.setAllFilled,
    );
  };

  public handleChangeCoordinate = (coordinate: string) => {
    this.setState({ coordinate, changed: true }, this.setAllFilled);
  };

  public validateEmail = (email: string): boolean => {
    // @TODO not RFC-822 compliant
    const re = /.+@.+/i;
    return re.test(email);
  };

  public isAllFilled = (): boolean => {
    const { username, email, emailError, validUntil, automation } = this.state;

    const isValidUntilSet = automation || validUntil !== '';

    return username !== '' && email !== '' && isValidUntilSet && !emailError && this.isPasswordFieldValid();
  };

  public setAllFilled = (): void => {
    this.setState({ allFilled: this.isAllFilled() });
  };

  public isPasswordFieldValid = (): boolean => {
    const { passwordEditable } = this.props;
    const { action, password, passwordConfirm } = this.state;

    if (!passwordEditable) {
      return true;
    }

    if (action === PanelAction.EDIT && password === '') {
      return true;
    }

    return password !== '' && password === passwordConfirm;
  };

  public onSave = async () => {
    const { user, closeHandler, saveHandler } = this.props;
    const { username, email, validUntil, password, coordinate, automation } = this.state;

    this.setState({ loading: true });

    try {
      if (user !== undefined) {
        const { id } = user;
        const saveableUser: UserUpdatePayload = {
          id,
          email,
          validUntil,
          coordinate,
        };

        await saveHandler(saveableUser, password !== '' ? password : undefined);
      } else {
        const saveableUser: UserCreatePayload = {
          username,
          email,
          validUntil: automation ? undefined : validUntil,
          coordinate,
          automation,
        };

        await saveHandler(saveableUser, password !== '' ? password : undefined);
      }

      this.setState({ loading: false });
      closeHandler();
    } catch (e) {
      enqueueSnackbar((e as Error).message, { variant: 'error' });
      this.setState({ loading: false });
    }
  };

  public onCancel = () => {
    const { closeHandler } = this.props;
    this.resetState();
    closeHandler();
  };

  public onDelete = () => {
    this.setState({ deleteDialogOpen: true });
  };

  public onDeleteDismiss = () => {
    this.setState({ deleteDialogOpen: false });
  };

  public onDeleteOk = async () => {
    const { user, closeHandler, deleteHandler } = this.props;

    this.setState({
      loading: true,
      deleteDialogOpen: false,
    });

    if (!user) {
      this.setState({ loading: false }, () => closeHandler());

      return;
    }

    await deleteHandler(user);

    this.setState({ loading: false }, () => closeHandler());
  };

  public render() {
    const { classes, user, passwordEditable } = this.props;

    const {
      action,
      username,
      email,
      validUntil,
      password,
      passwordConfirm,
      emailError,
      changed,
      loading,
      deleteDialogOpen,
      allFilled,
      coordinate,
      automation,
    } = this.state;

    const deleteDialogText = user ? `Delete User ${user.username}?` : '';

    return (
      <>
        {action === PanelAction.EDIT && (
          <GenericDialog
            type="confirmation"
            open={deleteDialogOpen}
            title={'Delete User'}
            text={deleteDialogText}
            onClose={this.onDeleteDismiss}
            onConfirm={this.onDeleteOk}
          />
        )}

        {loading && <LoadingMask />}

        <Grid container spacing={2} alignItems={'stretch'}>
          <Grid item lg={4} md={6} xs={12}>
            <Fade in={true} timeout={fadeTimeout}>
              <Stack direction={'column'} spacing={1}>
                <StyledTextField
                  label={'Username'}
                  value={username}
                  name={'username'}
                  onChange={this.onChange}
                  variant={'outlined'}
                  disabled={user !== undefined && user.id !== undefined}
                />
                <StyledTextField
                  label={'E-Mail'}
                  value={email}
                  name={'email'}
                  type={'email'}
                  error={emailError}
                  onChange={this.onChange}
                  variant={'outlined'}
                />
                <CoordinateInput onChange={this.handleChangeCoordinate} value={coordinate} trailingDelimiter={false} />
                {user == undefined && (
                  <StyledCheckbox
                    label={'Automation-User'}
                    value={automation}
                    onChange={() => this.setState({ automation: !automation }, this.setAllFilled)}
                  />
                )}
              </Stack>
            </Fade>
          </Grid>

          {passwordEditable && (
            <>
              <Grid item lg={4} md={6} xs={12}>
                <Fade in={true} timeout={fadeTimeout}>
                  <Stack direction={'column'} spacing={1}>
                    {passwordEditable && (
                      <>
                        <StyledTextField
                          type={'password'}
                          label={'Password'}
                          value={password}
                          name={'password'}
                          onChange={this.onChange}
                          variant={'outlined'}
                        />
                        <StyledTextField
                          type={'password'}
                          label={'Password-Confirm'}
                          value={passwordConfirm}
                          name={'passwordConfirm'}
                          onChange={this.onChange}
                          variant={'outlined'}
                          error={password !== passwordConfirm}
                        />
                      </>
                    )}
                    {!automation && (
                      <div>
                        <DatePicker
                          className={classes.margin}
                          label="Valid-Until"
                          disablePast
                          value={validUntil === '' ? moment() : moment(validUntil)}
                          onChange={this.handleChangeValidUntil}
                        />
                      </div>
                    )}
                  </Stack>
                </Fade>
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <FormPanelButtons
              cancelHandler={this.onCancel}
              resetHandler={this.resetState}
              saveHandler={this.onSave}
              deleteHandler={this.onDelete}
              isResetDisabled={!changed}
              isSaveDisabled={!changed || !allFilled}
              isDeleteDisabled={action !== PanelAction.EDIT || !user}
              isDeleteHidden={action !== PanelAction.EDIT || !user}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}

const StyleWrapped = withStyles(styles)(UserPanelComponent);

export const UserPanel = StyleWrapped;
