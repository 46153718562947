import { EslManagerPrivateRoute, HttpMethod } from '@ekkogmbh/apisdk';
import { Autocomplete, CircularProgress } from '@mui/material';
import { debounce } from '@mui/material/utils';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { inject } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import React, { CSSProperties } from 'react';
import Highlighter from 'react-highlight-words';
import { request } from '../Helper/FetchHandler';
import { CancelableFetchPromises, cancelFetchPromises } from '../Helper/PromiseHelper';
import { ApiStore } from '../Stores/ApiStore';
import { FormStyles } from '../Styles/FormStyles';
import { StyledTextField } from './Forms/StyledTextField';

interface Stores {
  api: ApiStore;
}

interface CoordinateInputProps extends WithStyles<typeof FormStyles> {
  disabled?: boolean;
  forwardedRef?: React.ForwardedRef<HTMLDivElement>;
  freeText?: boolean;
  label?: string;
  onChange: (coordinate: string) => void;
  trailingDelimiter: boolean | 'both';
  trimPrefix?: string;
  value?: string;
}

interface CoordinateInputState {
  lastInput: string | undefined;
  loading: boolean;
  options: readonly string[];
}

@inject('api')
class CoordinateInputComponent extends React.Component<CoordinateInputProps, CoordinateInputState> {
  state: CoordinateInputState = {
    lastInput: undefined,
    loading: false,
    options: [],
  };
  private fetchPromises: CancelableFetchPromises = {};

  get stores(): Stores {
    return this.props as CoordinateInputProps & Stores;
  }

  async componentDidMount() {
    await this.queryBackend(undefined, this.props.trailingDelimiter);
  }

  componentDidUpdate(prevProps: Readonly<CoordinateInputProps>) {
    if (
      prevProps.trailingDelimiter !== this.props.trailingDelimiter ||
      prevProps.trimPrefix !== this.props.trimPrefix
    ) {
      this.queryBackend(this.state.lastInput, this.props.trailingDelimiter);
    }
  }

  private debouncedQuery = debounce((query: string) =>
    this.queryBackend(query, this.props.trailingDelimiter).finally(),
  );

  private handleChange = (coordinate: string) => {
    const { trimPrefix } = this.props;

    let propagatedCoordinate = coordinate;
    if (trimPrefix !== undefined && trimPrefix.trim().length > 0 && coordinate.startsWith(trimPrefix)) {
      propagatedCoordinate = coordinate.replace(trimPrefix, '');
    }

    this.props.onChange(propagatedCoordinate);
    this.setState({ lastInput: propagatedCoordinate });
    this.debouncedQuery(coordinate);
  };

  private onErrorCallback = () => {
    this.setState({ loading: false, options: [] });
    console.error('failed getting accessible coordinates!');
  };

  private async queryBackend(input?: string, trailingDelimiter?: boolean | 'both'): Promise<void> {
    const { api } = this.stores;
    const { trimPrefix } = this.props;
    const query = trimPrefix == undefined && input == undefined ? undefined : (trimPrefix ?? '') + (input ?? '');

    this.setState({ loading: true });

    try {
      const coordinates = (
        await request<string[]>(
          api,
          enqueueSnackbar,
          this.fetchPromises,
          api.getAccessibleCoordinates(query, trailingDelimiter),
          EslManagerPrivateRoute.ACCESSIBLE_COORDINATES,
          HttpMethod.GET,
          undefined,
          undefined,
          this.onErrorCallback,
        )
      )
        .filter((suggested) => trimPrefix == undefined || suggested.length >= trimPrefix.length + 2)
        .map((suggested) => (trimPrefix == undefined ? suggested : suggested.substring(trimPrefix.length)));

      this.setState({ loading: false, options: coordinates });
    } catch (e) {
      cancelFetchPromises(this.fetchPromises);
      this.setState({ loading: false });
    }
  }

  public render(): React.ReactNode {
    const { classes, disabled, forwardedRef, freeText, label, value } = this.props;
    const { loading, options } = this.state;

    return (
      <Autocomplete
        id={`coordinate-input-${label}`}
        ref={forwardedRef}
        classes={classes}
        disabled={disabled === true}
        freeSolo={freeText !== false}
        options={options}
        loading={loading}
        filterOptions={(x) => x}
        inputValue={value}
        autoHighlight
        onChange={(_, value) => this.handleChange(value ?? '')}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            type="text"
            label={label ?? 'Coordinate'}
            onChange={(e) => this.handleChange(e.target.value)}
            inputProps={{ ...params.inputProps }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading && <CircularProgress size={20} />}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        renderOption={(props, option, { inputValue }) => (
          <li {...props}>
            <div>
              <Highlighter
                searchWords={[inputValue]}
                textToHighlight={option}
                highlightStyle={highlightStyle}
                unhighlightStyle={unhighlightStyle}
              />
            </div>
          </li>
        )}
      />
    );
  }
}

const unhighlightStyle: CSSProperties = {};
const highlightStyle: CSSProperties = {
  ...unhighlightStyle,
  backgroundColor: '#BBB',
};

const StyledCoordinateInput = withStyles(FormStyles)(CoordinateInputComponent);

// eslint-disable-next-line react/display-name
export const CoordinateInput = React.forwardRef<HTMLDivElement, Omit<CoordinateInputProps, 'classes'>>((props, ref) => (
  <StyledCoordinateInput {...props} forwardedRef={ref} />
));
