import {
  EslManagerPrivateRoute,
  HttpMethod,
  PaginationResponse,
  Role,
  User,
  UserRoleNodeMapping,
  UserRoleNodeMappingPayload,
} from '@ekkogmbh/apisdk';
import { Fade, Grid, Stack } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { DatePicker } from '@mui/x-date-pickers';
import { inject } from 'mobx-react';
import moment, { Moment } from 'moment';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { FormPanelButtons } from '../../Common/Components/FormPanelButtons';
import { StyledSelectField } from '../../Common/Components/Forms/StyledSelectField';
import { LoadingMask } from '../../Common/Components/LoadingMask';
import { ApiStore } from '../../Common/Stores/ApiStore';
import { FormStyles } from '../../Common/Styles/FormStyles';
import { CancelableFetchPromises, cancelFetchPromises, noop } from 'src/Common/Helper/PromiseHelper';
import { request } from 'src/Common/Helper/FetchHandler';
import { CoordinateInput } from 'src/Common/Components/CoordinateInput';
import { StyledTextField } from 'src/Common/Components/Forms/StyledTextField';

const styles = FormStyles;
const fadeTimeout = 2000;

const stores = ['api'];

interface UserMappingPanelStores {
  api: ApiStore;
}

interface UserMappingPanelState {
  validUntil: string;
  changed: boolean;
  loading: boolean;
  allFilled: boolean;
  coordinate: string;
  availableRoles: Role[];
  availableUsers: User[];
  selectedUser?: User;
  selectedRole?: Role;
}

interface UserMappingPanelProps extends WithStyles<typeof styles> {
  user?: User;
  role?: Role;
  closeHandler: () => void;
  saveHandler: (mapping: UserRoleNodeMappingPayload) => Promise<UserRoleNodeMapping>;
}

@inject(...stores)
class UserMappingPanelComponent extends React.PureComponent<UserMappingPanelProps, UserMappingPanelState> {
  public state: UserMappingPanelState = {
    coordinate: '',
    validUntil: '',
    changed: false,
    loading: false,
    allFilled: false,
    availableRoles: [],
    availableUsers: [],
  };
  private fetchPromises: CancelableFetchPromises = {};

  get stores(): UserMappingPanelStores {
    return this.props as UserMappingPanelProps & UserMappingPanelStores;
  }

  public handleError = (status: number, response: Response, json: Record<string, unknown>): void => {
    if (status > 400 && status <= 500) {
      enqueueSnackbar(response.statusText + ': ' + json.message, { variant: 'error' });
    }
  };

  public async componentDidMount(): Promise<void> {
    this.setState({ loading: true });

    const roleFetch = this.fetchRoles();
    const userFetch = this.fetchUsers();
    await Promise.all([roleFetch, userFetch]);

    this.setState(
      {
        loading: false,
      },
      this.setAllFilled,
    );
  }

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  public handleReset = () => {
    const { user, role } = this.props;
    const { availableRoles, availableUsers } = this.state;

    this.setState({
      changed: false,
      allFilled: false,
      coordinate: '',
      validUntil: '',
      selectedUser: user ?? availableUsers[0],
      selectedRole: role ?? availableRoles[0],
    });
  };

  public handleChangeValidUntil = (date: Moment | null) => {
    if (date == null) {
      this.setState({
        allFilled: false,
        changed: true,
      });

      return;
    }

    const changed = true;

    const validUntil = date.format('YYYY-MM-DD');

    this.setState(
      {
        validUntil,
        changed,
      },
      this.setAllFilled,
    );
  };

  public setAllFilled = (): void => {
    const { coordinate, validUntil, selectedRole, selectedUser } = this.state;
    const hasUser = selectedUser !== undefined;
    const hasRole = selectedRole !== undefined;

    const isValidUntilSet = (selectedUser && selectedUser.automation) || validUntil !== '';

    this.setState({ allFilled: hasUser && hasRole && coordinate !== '' && isValidUntilSet });
  };

  public handleSave = async () => {
    const { closeHandler, saveHandler } = this.props;
    const { selectedRole, selectedUser, coordinate, allFilled, validUntil, loading } = this.state;

    if (!allFilled || loading) {
      return;
    }

    this.setState({ loading: true });

    try {
      await saveHandler({
        user: selectedUser!,
        role: selectedRole!,
        coordinate,
        validUntil,
      });

      this.setState({ loading: false });
      closeHandler();
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  public handleChangeCoordinate = async (coordinate: string) => {
    this.setState(
      {
        coordinate,
        changed: true,
      },
      this.setAllFilled,
    );
  };

  public fetchRoles = async (): Promise<void> => {
    const { api } = this.stores;
    const { role, closeHandler } = this.props;

    const paginatedRoles = await request<PaginationResponse<Role>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getRoles({ page: 1, limit: 99 }),
      EslManagerPrivateRoute.ROLES,
      HttpMethod.GET,
    );

    const roles = paginatedRoles.items ?? [];
    if (!!role && roles.length < 1) {
      enqueueSnackbar<'error'>('no mappable roles found');
      closeHandler();
    }

    this.setState({
      selectedRole: role !== undefined ? role : roles[0],
      availableRoles: roles,
    });
  };

  public fetchUsers = async (): Promise<void> => {
    const { api } = this.stores;
    const { closeHandler, user } = this.props;

    const paginatedUsers = await request<PaginationResponse<User>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getUsers({ page: 1, limit: 99 }),
      EslManagerPrivateRoute.USERS,
      HttpMethod.GET,
    );

    const users = paginatedUsers.items ?? [];
    if (!!user && users.length < 1) {
      enqueueSnackbar<'error'>('no mappable users found');
      closeHandler();
    }

    this.setState({
      selectedUser: user !== undefined ? user : users[0],
      availableUsers: users,
    });
  };

  public onChangeUserById = (userId: number): void => {
    const { availableUsers } = this.state;

    const usr = availableUsers.find((u: User) => parseInt(u.id) === userId);

    this.setState({ selectedUser: usr }, this.setAllFilled);
  };

  public onChangeRoleById = (roleId: number): void => {
    const { availableRoles } = this.state;

    const role = availableRoles.find((r: Role) => r.id === roleId);

    this.setState({ selectedRole: role }, this.setAllFilled);
  };

  public renderUserComponent = () => {
    const { user } = this.props;

    if (user) {
      // the text field is visually consistent with the select
      return (
        <StyledTextField
          label={'User'}
          value={user.username}
          disabled={true}
          onChange={() => {
            noop;
          }}
        />
      );
    }

    const { selectedUser, availableUsers } = this.state;

    const userOptions = availableUsers.map((u: User, index: number) => (
      <option key={index} value={u.id}>
        {u.username}
      </option>
    ));

    return (
      <div>
        <StyledSelectField
          native
          label="User"
          value={selectedUser?.id}
          onChange={(e) => this.onChangeUserById(parseInt(e.target.value as string))}
        >
          {userOptions}
        </StyledSelectField>
      </div>
    );
  };

  public renderRoleComponent = () => {
    const { role } = this.props;

    if (role) {
      // the text field is visually consistent with the select
      return (
        <StyledTextField
          label={'Role'}
          value={role.name}
          disabled={true}
          onChange={() => {
            noop;
          }}
        />
      );
    }

    const { selectedRole, availableRoles } = this.state;

    const roleOptions = availableRoles.map((r: Role, index: number) => (
      <option key={index} value={r.id}>
        {r.name}
      </option>
    ));

    return (
      <div>
        <StyledSelectField
          native
          label="Role"
          value={selectedRole?.id}
          onChange={(e) => this.onChangeRoleById(parseInt(e.target.value as string))}
        >
          {roleOptions}
        </StyledSelectField>
      </div>
    );
  };

  public render() {
    const { classes, closeHandler } = this.props;
    const {
      selectedRole,
      selectedUser,
      availableRoles,
      allFilled,
      changed,
      loading,
      validUntil,
      coordinate,
    } = this.state;

    const roleOptions = availableRoles.map(({ id, name }: Role, index: number) => (
      <option key={index} value={String(id)}>
        {name}
      </option>
    ));

    roleOptions.unshift(<option key={-1} value="" />);

    return (
      <>
        {loading && <LoadingMask />}

        <Grid container spacing={2} alignItems={'stretch'}>
          <Grid item lg={5} md={8} xs={12}>
            <Fade in={true} timeout={fadeTimeout}>
              <Stack direction={'column'} spacing={1}>
                {selectedUser && this.renderUserComponent()}
                {selectedRole && this.renderRoleComponent()}
                <CoordinateInput onChange={this.handleChangeCoordinate} value={coordinate} trailingDelimiter={false} />
                {selectedUser && !selectedUser.automation && (
                  <div>
                    <DatePicker
                      className={classes.margin}
                      label="Valid-Until"
                      disablePast
                      value={validUntil === '' ? moment() : moment(validUntil)}
                      onChange={this.handleChangeValidUntil}
                    />
                  </div>
                )}
              </Stack>
            </Fade>
          </Grid>

          <Grid item xs={12}>
            <FormPanelButtons
              cancelHandler={closeHandler}
              resetHandler={this.handleReset}
              saveHandler={this.handleSave}
              deleteHandler={undefined}
              isResetDisabled={!changed}
              isSaveDisabled={!changed || !allFilled}
              isDeleteDisabled={true}
              isDeleteHidden={true}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}

const StyleWrapped = withStyles(styles)(UserMappingPanelComponent);

export const UserMappingPanel = StyleWrapped;
