import { CombinedTrigger, EventTrigger, EventType } from '@ekkogmbh/apisdk';
import { action, observable } from 'mobx';
import { copy } from 'src/Common/Helper/FormHelper';

export interface EventTriggerStoreState {
  triggers: EventTrigger[];
  changed: boolean;
}

export class EventTriggerStore {
  @observable
  public state: EventTriggerStoreState = {
    triggers: [],
    changed: false,
  };

  @action
  public setTriggers(triggers: EventTrigger[]): void {
    this.state.triggers = triggers;
    this.state.changed = true;
  }

  @action
  public reset(initialTrigger?: EventTrigger): void {
    if (initialTrigger) {
      const initialCopy = copy(initialTrigger);

      if (initialTrigger.type === 'combined') {
        this.state.triggers = (initialCopy as CombinedTrigger).properties.triggers;
      } else {
        this.state.triggers = [initialCopy];
      }
    } else {
      this.state.triggers = [];
    }

    this.state.changed = false;
  }

  public addTrigger(trigger: EventTrigger): void {
    const { triggers } = this.state;
    triggers.push(trigger);
    this.setTriggers(triggers);
  }

  @action
  public removeTrigger(triggerIndex: number): void {
    const { triggers } = this.state;
    triggers.splice(triggerIndex, 1);
    this.setTriggers(triggers);
  }

  public setTrigger(triggerIndex: number, trigger: EventTrigger): void {
    const { triggers } = this.state;
    triggers[triggerIndex] = trigger;
    this.setTriggers(triggers);
  }

  public isSavable(): boolean {
    return this.state.triggers.length > 0;
  }

  public getDefaultTrigger(type: EventType | string): EventTrigger {
    switch (type) {
      case EventType.COMPARTMENT_FOUND:
        return {
          type,
          properties: {
            finderName: '',
            finderCoordinate: '',
            input: '',
          },
        };
      case EventType.DEVICE_TRIGGER:
        return {
          type,
          properties: {
            deviceTriggerType: 'button-pressed',
            deviceTriggerIndex: '',
            deviceId: '',
          },
        };
      case EventType.DEVICE_GEOFENCE:
        return {
          type,
          properties: {
            locationTrackingEventType: 'entry',
            fenceName: '',
          },
        };
      case EventType.EKANBAN_STATE_TRANSITION:
        return {
          type,
          properties: {
            receivingAreaName: '',
            replenishmentPlanName: '',
            fromState: '',
            toState: '',
          },
        };
      case EventType.PICKING_ORDER_CONCLUDED:
        return {
          type,
          properties: {
            externalId: '',
            zoneName: '',
          },
        };
      case 'dashboard':
        return {
          type: EventType.GENERIC,
          properties: {
            context: 'dashboard',
          },
        };
      case 'event-rule-action':
        return {
          type: EventType.GENERIC,
          properties: { context: 'event-rule-action', key: '' },
        };
      default:
        return {
          type: EventType.GENERIC,
          properties: {
            context: '',
            key: '',
          },
        };
    }
  }

  public hasTriggerOfType(type: EventType): boolean {
    const names = this.state.triggers.map((t) => t.type);

    return names.indexOf(type) >= 0;
  }

  public pruneUnsetProperties = (trigger: EventTrigger): void => {
    // remove key from internal context keywords
    if (trigger.type === EventType.GENERIC && ['dashboard'].indexOf(trigger.properties.context as string) >= 0) {
      delete trigger.properties.key;
    }

    // remove empty strings
    Object.keys(trigger.properties).forEach((propKey) => {
      if (trigger.properties[propKey] === '') {
        delete trigger.properties[propKey];
      }
    });
  };

  public getConsolidatedTrigger(): EventTrigger {
    const { triggers } = this.state;

    if (triggers.length === 1) {
      this.pruneUnsetProperties(triggers[0]);
      return triggers[0];
    } else {
      triggers.forEach((t) => this.pruneUnsetProperties(t));
      return {
        type: 'combined',
        properties: {
          triggers: triggers,
        },
      };
    }
  }
}
