import { ReceivingArea } from '@ekkogmbh/apisdk';
import { Delete, Edit } from '@mui/icons-material';
import { MUIDataTableColumnDef } from 'mui-datatables';
import React from 'react';
import { DatatableColumnDefinitionFn } from 'src/Common/Components/DataTable';
import { ActionButton, DataTableActionsComponent } from 'src/Common/Components/DataTable/DataTableActions';
import {
  ReceivingAreaManagementContentActionHandlers,
  ReceivingAreaManagementContentPropsWithStores,
  ReceivingAreaManagementContentState,
} from './ReceivingAreaManagementContent';
import { Chip, Stack, Tooltip, Typography } from '@mui/material';

class ReceivingAreaDataTableActions extends DataTableActionsComponent<ReceivingArea> {}

const Actions = (
  _: ReceivingAreaManagementContentState,
  __: ReceivingAreaManagementContentPropsWithStores,
  actions: ReceivingAreaManagementContentActionHandlers,
): MUIDataTableColumnDef => ({
  label: ' ',
  name: 'entry',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (ReceivingArea: ReceivingArea): React.ReactNode => {
      const actionButtons: ActionButton<ReceivingArea>[] = [
        {
          title: 'Edit',
          onClick: actions.edit,
          icon: Edit,
        },
        {
          title: 'Delete',
          onClick: actions.delete,
          icon: Delete,
        },
      ];

      return (
        <ReceivingAreaDataTableActions dataset={ReceivingArea} isProcessing={false} actionButtons={actionButtons} />
      );
    },
  },
});

const Name = (): MUIDataTableColumnDef => ({
  label: 'Name',
  name: 'entry.name',
  options: {
    sort: true,
    filter: false,
    customBodyRender: (name: ReceivingArea['name']): React.ReactNode => <div style={{ fontWeight: 700 }}>{name}</div>,
  },
});

const ReplenishmentPlan = (): MUIDataTableColumnDef => ({
  label: 'Replenishment Plan',
  name: 'entry.replenishmentPlan',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (replenishmentPlan: ReceivingArea['replenishmentPlan']): React.ReactNode => {
      const machine = replenishmentPlan.linearStateMachineDefinition;

      return (
        <Tooltip
          arrow
          title={
            <Stack direction={'column'} alignItems={'center'}>
              <Typography variant={'overline'}>states</Typography>
              <Stack direction={'row'} spacing={1} justifyContent={'space-between'}>
                {machine.states.map((state: string, index: number) => (
                  <Stack key={index} direction={'column'}>
                    <Typography fontWeight={700}>{state}</Typography>
                    <Typography>{machine.triggers[state].type}</Typography>
                    <Typography>{machine.onEnterActions[state].name}</Typography>
                  </Stack>
                ))}
              </Stack>
            </Stack>
          }
        >
          <Chip
            sx={{ height: 'auto' }}
            variant={'outlined'}
            label={
              <Stack>
                <Typography
                  color={'textPrimary'}
                  style={{ fontSize: 16, lineHeight: 'normal', fontWeight: 700, userSelect: 'none' }}
                >
                  {replenishmentPlan.name}
                </Typography>
                <Typography color={'textSecondary'} style={{ fontSize: 12, lineHeight: 'normal', userSelect: 'none' }}>
                  {replenishmentPlan.coordinate}
                </Typography>
              </Stack>
            }
          />
        </Tooltip>
      );
    },
  },
});

const Coordinate = (): MUIDataTableColumnDef => ({
  label: 'Coordinate',
  name: 'entry.coordinate',
  options: {
    sort: true,
    filter: false,
    customBodyRender: (coordinate: ReceivingArea['coordinate']): React.ReactNode => (
      <div style={{ fontWeight: 700 }}>{coordinate}</div>
    ),
  },
});

export const materialDatatableColumnDefinitions: Array<DatatableColumnDefinitionFn<
  ReceivingAreaManagementContentPropsWithStores,
  ReceivingAreaManagementContentState,
  ReceivingAreaManagementContentActionHandlers
>> = [Name, Coordinate, ReplenishmentPlan, Actions];
