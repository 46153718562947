import {
  EslManagerPublicRouteV1,
  HttpMethod,
  Pagination,
  PaginationResponse,
  ReplenishmentPlan,
} from '@ekkogmbh/apisdk';
import { SaveReplenishmentPlanPayload } from '@ekkogmbh/apisdk/src/Sdk/Ekanban/EkanbanTypes';
import { Add } from '@mui/icons-material';
import { Grid, Paper } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import { inject } from 'mobx-react';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { enqueueSnackbar } from 'notistack';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { ContentPanelDefinition, ContentPanels } from '../../Common/Components/ContentPanels';
import { DataTable, DataTableFilterFields, DataTableSortFieldMap } from '../../Common/Components/DataTable';
import { GenericDialog } from '../../Common/Components/GenericDialog';
import { request } from '../../Common/Helper/FetchHandler';
import { CancelableFetchPromises, cancelFetchPromises, noop } from '../../Common/Helper/PromiseHelper';
import { ApiStore, Permissions } from '../../Common/Stores/ApiStore';
import { NavigationStore } from '../../Common/Stores/NavigationStore';
import { PaginationStore } from '../../Common/Stores/PaginationStore';
import { SearchContentStore } from '../../Common/Stores/SearchContentStore';
import { ReplenishmentPlanStore } from '../Stores/ReplenishmentPlanStore';
import { ReplenishmentPlanManagementStyles } from '../Styles/ReplenishmentPlanManagementStyles';
import { materialDatatableColumnDefinitions } from './ReplenishmentPlanDatatableColumnDefinitions';
import { ReplenishmentPlanPanel, ReplenishmentPlanPanelProps } from './ReplenishmentPlanPanel';
import { EventTriggerStore } from 'src/EventRuleManagement/Stores/EventTriggerStore';

interface ReplenishmentPlanManagementContentActions {
  updateReplenishmentPlan?: (replenishmentPlan: ReplenishmentPlan) => void;
}

export interface ReplenishmentPlanManagementContentActionHandlers {
  edit: (replenishmentPlan: ReplenishmentPlan) => void;
  delete: (replenishmentPlan: ReplenishmentPlan) => void;
}

export interface ReplenishmentPlanManagementContentState {
  deletableReplenishmentPlan?: ReplenishmentPlan;
}

interface ReplenishmentPlanManagementContentStores {
  api: ApiStore;
  replenishmentPlanStore: ReplenishmentPlanStore;
  eventTriggerStore: EventTriggerStore;
  paginationStore: PaginationStore;
  searchContentStore: SearchContentStore;
  navigationStore: NavigationStore;
}

const stores = [
  'api',
  'replenishmentPlanStore',
  'eventTriggerStore',
  'paginationStore',
  'searchContentStore',
  'navigationStore',
];

interface ReplenishmentPlanManagementContentProps
  extends WithStyles<typeof ReplenishmentPlanManagementStyles>,
    RouteComponentProps {}

export type ReplenishmentPlanManagementContentPropsWithStores = ReplenishmentPlanManagementContentProps &
  ReplenishmentPlanManagementContentStores;

@inject(...stores)
class ReplenishmentPlanManagementContentComponent extends Component<
  ReplenishmentPlanManagementContentProps,
  ReplenishmentPlanManagementContentState
> {
  private readonly filterFields: DataTableFilterFields<ReplenishmentPlan> = ['name', 'coordinate'];
  private readonly sortFieldMap: DataTableSortFieldMap<ReplenishmentPlan> = { name: 'entry.name' };
  private readonly actions: ReplenishmentPlanManagementContentActions = {};

  private fetchPromises: CancelableFetchPromises = {};

  public state: ReplenishmentPlanManagementContentState = {};

  get stores(): ReplenishmentPlanManagementContentStores {
    return this.props as ReplenishmentPlanManagementContentProps & ReplenishmentPlanManagementContentStores;
  }

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  public fetch = async (pagination: Pagination): Promise<PaginationResponse<ReplenishmentPlan>> => {
    const { api } = this.stores;

    return await request<PaginationResponse<ReplenishmentPlan>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getReplenishmentPlans(pagination),
      EslManagerPublicRouteV1.REPLENISHMENT_PLANS,
      HttpMethod.GET,
    );
  };

  public onDelete = async (replenishmentPlan: ReplenishmentPlan): Promise<void> => {
    this.setState({ deletableReplenishmentPlan: replenishmentPlan });
  };

  public onDeleteDismiss = async () => {
    this.setState({ deletableReplenishmentPlan: undefined });
  };

  public onDeleteConfirm = async () => {
    const { deletableReplenishmentPlan } = this.state;
    const { api, searchContentStore } = this.stores;

    if (!deletableReplenishmentPlan) {
      return;
    }

    const { coordinate, name } = deletableReplenishmentPlan;

    await request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.deleteReplenishmentPlan({ coordinate, name }),
      EslManagerPublicRouteV1.REPLENISHMENT_PLAN,
      HttpMethod.DELETE,
      { 200: 'Replenishment Plan deleted.' },
    );

    this.onDeleteDismiss();
    searchContentStore.emitRefresh();
  };

  public onEdit = async (replenishmentPlan: ReplenishmentPlan): Promise<void> => {
    const { navigationStore } = this.stores;
    const { updateReplenishmentPlan } = this.actions;

    if (updateReplenishmentPlan) {
      updateReplenishmentPlan(replenishmentPlan);
      navigationStore!.scrollTop();
    }
  };

  public onSave = async (replenishmentPlan: ReplenishmentPlan): Promise<ReplenishmentPlan> => {
    const { api, navigationStore, searchContentStore, replenishmentPlanStore } = this.stores;

    const payload: SaveReplenishmentPlanPayload = {
      linearStateMachineDefinition: replenishmentPlan.linearStateMachineDefinition,
    };

    const responseReplenishmentPlan = await request<ReplenishmentPlan>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.saveReplenishmentPlan(
        {
          name: replenishmentPlan.name,
          coordinate: replenishmentPlan.coordinate,
          ...payload,
        },
        replenishmentPlanStore.editableReplenishmentPlan !== undefined,
      ),
      EslManagerPublicRouteV1.REPLENISHMENT_PLAN,
      HttpMethod.PUT,
      { 200: 'Replenishment Plan saved.' },
    );

    navigationStore.scrollTop();
    searchContentStore.emitRefresh();

    return responseReplenishmentPlan;
  };

  public createPanels = (): ContentPanelDefinition[] => {
    const { replenishmentPlanStore } = this.stores;

    const addPanelDefinition: ContentPanelDefinition<ReplenishmentPlanPanelProps> = {
      name: 'Add',
      icon: Add,
      isHidden: false,
      panelComponent: ReplenishmentPlanPanel,
      panelProps: {
        closeHandler: noop,
        saveHandler: this.onSave,
      },
      permission: Permissions.FINDERS_WRITE,
      toggleOffCallback: replenishmentPlanStore.resetStore,
      expandHandler: (expandCallback: () => void) => {
        const { updateReplenishmentPlan } = this.actions;
        if (updateReplenishmentPlan === undefined) {
          this.actions.updateReplenishmentPlan = async (replenishmentPlan: ReplenishmentPlan) => {
            expandCallback();
            replenishmentPlanStore.setEditableReplenishmentPlan(replenishmentPlan);
          };
        }
      },
    };

    return [addPanelDefinition];
  };

  public render() {
    const { classes } = this.props;
    const { deletableReplenishmentPlan } = this.state;
    const columnDefinition: MUIDataTableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(this.state, this.props as ReplenishmentPlanManagementContentPropsWithStores, {
        edit: this.onEdit,
        delete: this.onDelete,
      }),
    );

    return (
      <Grid item xs={12}>
        <ContentPanels panels={this.createPanels()} />

        {deletableReplenishmentPlan && (
          <GenericDialog
            type="confirmation"
            maxWidth={'sm'}
            fullWidth={true}
            centered={true}
            open={!!deletableReplenishmentPlan}
            title={'Delete Replenishment Plan'}
            text={deletableReplenishmentPlan.name}
            onClose={this.onDeleteDismiss}
            onConfirm={this.onDeleteConfirm}
          />
        )}

        <Paper className={classNames(classes.root, classes.dataTablePaper)}>
          <DataTable
            columns={columnDefinition}
            fetchItems={this.fetch}
            filterFields={this.filterFields}
            sortFieldMap={this.sortFieldMap}
            options={{
              sortOrder: { name: materialDatatableColumnDefinitions[0].name, direction: 'desc' },
            }}
          />
        </Paper>
      </Grid>
    );
  }
}

const RouterWrapped = withRouter<
  ReplenishmentPlanManagementContentProps,
  typeof ReplenishmentPlanManagementContentComponent
>(ReplenishmentPlanManagementContentComponent);
const StyleWrapped = withStyles(ReplenishmentPlanManagementStyles)(RouterWrapped);

export const ReplenishmentPlanManagementContent = StyleWrapped;
